import React, { useState } from "react"
import HoverLine from "../hover-line"
import { TRANSITION_HEADER } from "../../../styles/common"
import { Link } from "gatsby"

const MenuItem = ({
  children,
  color,
  to,
  fontSize = 1.8,
  hoverLine = true,
  currentSiteUrl
}) => {
  const [hover, setHover] = useState(false)
  const menuItemStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginLeft: "0.5rem",
    marginRight: "0.5rem",
    cursor: hover ? "pointer" : "default",
  }

  const labelStyle = {
    marginTop: "0.5rem",
    marginBottom: "0.5rem",
    color: color,
    fontWeight: "400",
    fontSize: fontSize + "rem",
    transition: TRANSITION_HEADER,
  }
  return (
    <Link
      tabIndex={0}
      role='button'
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      to={to}
      className="menu-item"
    >
      <li style={menuItemStyle}>
        {hoverLine && (
          <HoverLine
            show={hover}
            color={color}
            size='0.1'
            position='relative'
          />
        )}
        <div style={labelStyle}>{children}</div>
        {hoverLine && (
          <HoverLine
            show={hover || to === currentSiteUrl}
            bottom
            invert
            color={color}
            size='0.1'
            position='relative'
          />
        )}
      </li>
    </Link>
  )
}

export default MenuItem
