import React from "react"
import ContactCard from "./components/contact-card"
import { DEFAULT_BLUE } from "../../../styles/common"
import Card from "./components/card"
import GoogleMapReact from "google-map-react"
import styled from "styled-components"
import { Icon } from "@material-ui/core"

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100vw;
  min-height: 20rem;
  background-color: ${DEFAULT_BLUE};
`
const MapWrapper = styled.div`
  min-width: 30rem;
  min-height: 20rem;
  width: calc(100vw - 40rem - 40rem);
`

const Footer = () => {

  const Marker = props => (
    <div><Icon fontSize="large">location_on</Icon></div>
  )
  return (
    <footer>
      <Wrapper>
        <ContactCard />
        <MapWrapper>
        </MapWrapper>
        <Card end title='Skapad av MW Webdesign'>
        </Card>
      </Wrapper>
    </footer>
  )
}

export default Footer
