import React from "react"
import Icon from "@material-ui/core/Icon"

const ContactRow = ({ icon, label }) => {
  const wrapperStyle = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  }

  const iconStyle = {
    marginRight: '1rem',
    color: 'white'
  }
  return (
    <div style={wrapperStyle}>
      <Icon style={iconStyle} fontSize="large">{icon}</Icon>
      <h4>{label}</h4>
    </div>
  )
}

export default ContactRow
