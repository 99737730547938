import React from "react"
import ContactRow from "../contact-row"
import { StaticQuery, graphql } from "gatsby"
import Card from "../card"

const ContactCard = () => {
  return (
    <StaticQuery
      query={graphql`
        query ContactQuery {
          prismic {
            allContacts {
              edges {
                node {
                  title
                  contact_fields {
                    icon
                    information
                  }
                }
              }
            }
          }
        }
      `}
      render={data => (
        <Card title={data.prismic.allContacts.edges[0].node.title[0].text} style={{width: '36rem'}}>
          {data.prismic.allContacts.edges[0].node.contact_fields.map((field, index) => (
            <ContactRow
              key={index}
              icon={field.icon[0].text}
              label={field.information[0].text}
            />
          ))}
        </Card>
      )}
    />
  )
}

export default ContactCard
