const blueRgb = "8, 41, 57"

export const DEFAULT_BLUE = `rgba(${blueRgb})`
export const DEFAULT_BLUE_90 = `rgba(${blueRgb},0.9)`
export const DEFAULT_BLUE_80 = `rgba(${blueRgb},0.8)`
export const DEFAULT_BLUE_70 = `rgba(${blueRgb}, 0.7)`
export const DEFAULT_BLUE_50 = `rgba(${blueRgb}, 0.6)`
export const TRANSITION_HEADER = "all 0.3s ease-in"
export const BREAD_MEDIUM = {
  fontSize: '2rem'
}
export const HOVER_LINE_WRAPPER = {
  display: 'flex', 
  width: 'fit-content'
}
export const DEFAULT_MAX_WIDTH = "120rem"
