import React from "react"
import HoverLine from "../../../../common/hover-line"
import { HOVER_LINE_WRAPPER } from '../../../../../styles/common'

const Card = ({ title, children, end }) => {
  const wrapperStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: end ? 'flex-end' : 'flex-start',
    alignItems: end ? 'flex-end' : 'flex-start',
    width: '36rem',
    minHeight: '20rem',
    paddingTop: "3rem",
    paddingLeft: end ? "0rem" : '4rem',
    paddingRight: end ? '4rem' : '0rem',
    color: "white",

  }
  const detailsWrapperStyle = {
    paddingTop: "1.5rem",
    color: 'lightgrey'
  }
  return (
    <div style={wrapperStyle}>
      <div style={HOVER_LINE_WRAPPER}>
        <h2>{title}</h2>
        <HoverLine show size='0.1' color='white' bottom />
      </div>
      <div style={detailsWrapperStyle}>
        {children}
      </div>
    </div>
  )
}

export default Card
