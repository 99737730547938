import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Menu from "../../common/menu"
import MenuItem from "../../common/menu-item"
import { DEFAULT_BLUE, TRANSITION_HEADER } from "../../../styles/common"
import styled from "styled-components"

const LatestNews = styled.div`
  position: absolute;
  right: 2rem;
  bottom: 3rem;
  transition: all 0.3s ease-in;
  &:hover {
    box-shadow: 0px 0px 5px 0px white;
  }
`

const Header = ({ transparent, currentSiteUrl }) => {
  const color = {
    background: DEFAULT_BLUE,
    details: "white",
  }

  const wrapperStyle = {
    width: "100vw",
    position: transparent ? "relative" : "fixed",
    marginBottom: `1.45rem`,
    zIndex: "1000",
    backgroundColor: color.background,
    transition: TRANSITION_HEADER,
  }

  const innerWrapperStyle = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
    justifyContent: "center",
    margin: `0 auto`,
    maxWidth: "130rem",
    padding: `1.45rem 1.0875rem`,
    zIndex: "1001",
  }
  const linkStyle = {
    transition: TRANSITION_HEADER,
    color: color.details,
    textDecoration: `none`,
  }

  return (
    <header style={wrapperStyle}>
      <div style={innerWrapperStyle}>
        <Link to='/' style={linkStyle}>
          <div
            className='logo'
            style={{ width: "375px", height: "70px", maxWidth: "75vw" }}
          ></div>
        </Link>
        <Menu>
          <MenuItem
            color={color.details}
            to='/bastu-tider'
            currentSiteUrl={currentSiteUrl}
          >
            Tider
          </MenuItem>
          <MenuItem
            color={color.details}
            to='/boka'
            currentSiteUrl={currentSiteUrl}
          >
            Boka Online
          </MenuItem>
          <MenuItem
            color={color.details}
            to='/om'
            currentSiteUrl={currentSiteUrl}
          >
            Om föreningen
          </MenuItem>
          <MenuItem
            color={color.details}
            to='/bli-medlem'
            currentSiteUrl={currentSiteUrl}
          >
            Bli medlem
          </MenuItem>
          <MenuItem
            color={color.details}
            to='/hyra'
            currentSiteUrl={currentSiteUrl}
          >
            Hyra lokalen
          </MenuItem>
          <MenuItem
            color={color.details}
            to='/bli-sponsor'
            currentSiteUrl={currentSiteUrl}
          >
            Bli sponsor
          </MenuItem>
          <MenuItem
            color={color.details}
            to='/galleri'
            currentSiteUrl={currentSiteUrl}
          >
            Galleri
          </MenuItem>
          <MenuItem
            color={color.details}
            to='/historia'
            currentSiteUrl={currentSiteUrl}
          >
            Historia
          </MenuItem>
          <MenuItem
            color={"rgba(0,0,0,0)"}
            to='#'
            currentSiteUrl={currentSiteUrl}
          >
            historiaaaaaaaa
          </MenuItem>
          <LatestNews className='latest-news'>
            <MenuItem
              color={color.details}
              to='nyheter'
              fontSize={1.8}
              hoverLine={false}
              currentSiteUrl={currentSiteUrl}
            >
              Senaste nytt
            </MenuItem>
          </LatestNews>
        </Menu>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
