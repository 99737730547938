import React, { useRef, useEffect, useState } from "react"

const HoverLine = ({
  invert = false,
  bottom = false,
  color = "rgba(75, 86, 84, 0.88",
  size = "0.2",
  position = "absolute",
  show,
}) => {
  const [dimensions, setDimensions] = useState({ width: "0", height: "0" })
  const hoverLineStyle = {
    display: "flex",
    width: show ? `${dimensions.width}px` : "00%",
    height: `${size}rem`,
    alignSelf: bottom ? "flex-end" : "flex-start",
    transition: position === "relative" ? "all 0.7s" : "",
    backgroundColor: color,
    opacity: show ? "1" : "0",
    position: position,
  }

  const stageCanvasRef = useRef(null)

  // useEffect will run on stageCanvasRef value assignment
  useEffect(() => {
    if (stageCanvasRef.current) {
      let width = stageCanvasRef.current.parentNode.offsetWidth
      let height = stageCanvasRef.current.parentNode.offsetHeight
      setDimensions({ width, height })
    }
  }, [stageCanvasRef])

  return <span ref={stageCanvasRef} style={hoverLineStyle}></span>
}
export default HoverLine
