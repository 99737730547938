import React, { useState } from "react"
import { Media } from "../../../media"
import { IconButton } from "@material-ui/core"
import Icon from "@material-ui/core/Icon"
import styled from 'styled-components';
import './menu.css'

const MobileMenuList = styled.ul`
  display: flex;
  flex-direction: column;
  width: 85vw;
  &.latest-news {
    position:relative;
  }
  padding-inline-start: 0px;
`

const Menu = ({ children }) => {
  const [showMenu, setShowMenu] = useState(false)
  const menuItemsListStyle = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    listStyleType: "none",
    paddingInlineStart: "2rem"
  }

  const renderMobileMenu = () => (
    <div className="menu-animation">
      <div>
        <IconButton onClick={() => setShowMenu(false)}>
          <Icon style={{ color: "white" }} fontSize='large'>
            close
          </Icon>
        </IconButton>
      </div>
      <MobileMenuList>{children}</MobileMenuList>
    </div>
  )
  return (
    <>
      <Media greaterThanOrEqual='md'>
        <ul style={menuItemsListStyle}>{children}</ul>
      </Media>
      <Media lessThan='md'>
        {showMenu ? (
          renderMobileMenu()
        ) : (
          <IconButton onClick={() => setShowMenu(true)}>
            <Icon style={{ color: "white" }} fontSize='large'>
              menu
            </Icon>
          </IconButton>
        )}
      </Media>
    </>
  )
}

export default Menu
